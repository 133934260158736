
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { mapKey } from '@/utils/config'
import AMapLoader from '@amap/amap-jsapi-loader'
import { PolygonStyle } from '@/types/common'
import { drawPolygon } from '@/utils/formatData'
@Component({
  components: { },
  name: 'seedlingInspectionAdd'
})

export default class ScheduleAdd extends Vue {
  private loading = false
  private loadingSubmit = false
  private projectUserList = []
  private seedlingseedlingTypeList = []
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private baseUrl = require('@/assets/img/seedlingseedling_gray.svg')
  private baseUrl2 = require('@/assets/img/seedlingseedling_green.svg')

  private polygonCurrentStyle: PolygonStyle = {
    strokeColor: '#3592FE',
    fillColor: '#3592FE',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 100
  }

  private InspectionType = [
    {
      label: '苗木',
      value: '1'
    },
    {
      label: '分区',
      value: '2'
    }
  ]

  private Code = '编号自动生成'

  private info = {
    checkName: '', // 盘点名称
    projectId: '', // 项目ID
    checkUserId: '', // 盘点人员ID
    checkType: '1', // 盘点类型
    checkBak: '', // checkBak
    seedlingCheckRecordList: [] // seedlingCheckRecordList
  }

  private searchInfo = {
    projectId: '',
    seedlingTypes: '',
    projectAreaIds: '',
    isFamous: '0',
    plantName: ''
  }

  private areaList = [] as any // 分区
  private markers = [] as any // 打点

  private tableData1 = [] as any // 按类别苗木
  private tableData2 = [] as any // 按分区苗木

  private rules = {
    checkName: [
      { required: true, message: '请输入盘点名称', trigger: ['blur', 'change'] }
    ],
    projectId: [
      { required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }
    ],
    checkUserId: [
      { required: true, message: '请选择盘点人员', trigger: ['blur', 'change'] }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  @Watch('info.projectId')
  projectIdChange (value: any) {
    // console.log('选择项目', value)
    this.getUserList(value)// 获取项目用户列表
    this.getAreaList(value) // 获取分区列表
    this.info.checkUserId = ''
    this.info.checkType = '1'
    this.info.seedlingCheckRecordList = []
    this.info.checkBak = ''
    this.searchInfo = {
      projectId: '',
      seedlingTypes: '',
      projectAreaIds: '',
      isFamous: '0',
      plantName: ''
    }
    this.searchInfo.projectId = this.info.projectId
    this.initMap()
    // this.map && this.map.remove(this.ploygons)
  }

  @Watch('info.checkType')
  checkTypeChange (val: any) {
    // console.log('info.checkType')
    this.info.seedlingCheckRecordList = []
    this.searchInfo.plantName = '';
    (this.$refs.table2 as any).clearSelection();
    (this.$refs.table as any).clearSelection()
    if (val === '1') {
      this.searchInfo.projectAreaIds = ''
      this.tableData2 = []
      this.map && this.map.clearMap()
    } else {
      this.searchInfo.seedlingTypes = ''
      this.tableData1 = []
    }
  }

  @Watch('searchInfo.seedlingTypes')
  seedlingTypesChange (val: any) {
    // console.log('val33', val)
    if (val.length) {
      const params = JSON.parse(JSON.stringify(this.searchInfo))
      params.seedlingTypes = params.seedlingTypes.join(',')
      this.getSeedlingseedList(params, false)
    } else {
      this.tableData1 = []
    }
  }

  @Watch('searchInfo.projectAreaIds')
  projectAreaIdsChange (val: any) {
    // console.log('区域id改变');
    (this.$refs.table2 as any).clearSelection()
    if (val) {
      this.getSeedlingseedList(this.searchInfo, true)
      this.getAreaDetail(val)
    }
  }

  created () {
    this.getSeedlingTypeList() // 苗木类型字典
  }

  // 初始化地图
  initMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then((AMap) => {
      // 拿到项目的经纬度
      const target = this.projectList.find((item: any) => {
        return item.projectId === this.info.projectId
      })
      const map = new AMap.Map('mapContainer', {
        center: [target.longitude, target.latitude],
        zoom: 11
      })
      this.map = map
    })
  }

  // 查询苗木类别列表
  getSeedlingTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'seedlingType' }).then(res => {
      this.seedlingseedlingTypeList = res.seedlingType || []
    })
  }

  // 项目人员列表
  getUserList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: id
    }).then(res => {
      // console.log('用户列表', res)
      this.projectUserList = res || []
    })
  }

  // 获取区域
  getAreaList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectAreaByList, {
      projectId: id
    }).then(res => {
      this.areaList = res || []
    })
  }

  // 区域详情
  getAreaDetail (val: any) {
    this.$axios.get(this.$apis.project.selectYhProjectAreaByProjectAreaId, {
      projectAreaId: val
    }).then((res) => {
      if (this.map) {
        this.map.remove(this.ploygons)
        // console.log('res区域', res)
        this.ploygons = drawPolygon(this.map, res.locationList, this.polygonCurrentStyle, null)
        this.map.setZoom(13)
      }
    })
  }

  // 获取苗木打点列表
  getSeedlingseedList (data: any, isUpdateMarkes: any) {
    console.log(data, isUpdateMarkes)
    this.$axios.get(this.$apis.seedlingInspection.selectYhSeedlingLocationList, data).then(res => {
      if (this.info.checkType === '1') {
        this.tableData1 = res || []
      } else {
        this.tableData2 = res || []
        if (isUpdateMarkes) {
          this.map && this.map.remove(this.markers)
          res.forEach((item: any) => {
            this.setMarker([item.longitude, item.latitude], item.locationId)
          })
        }
      }
    })
  }

  //  添加标记
  setMarker (lnglat: any, locationId: any) {
    // console.log('位置', lnglat) // lnglat=[经度,纬度]
    const marker = new AMap.Marker({
      position: lnglat,
      extData: locationId,
      icon: new AMap.Icon({
        size: new AMap.Size(25, 35),
        image: this.baseUrl, // 自己的图片
        imageSize: new AMap.Size(25, 35)
      })
    })
    this.map && this.map.add(marker)
    this.markers.push(marker) // 在data中记录标记点
  }

  // 选择苗木
  selectionLineChangeHandle (val: any) {
    // console.log('selectionLineChangeHandle', val)
    this.info.seedlingCheckRecordList = val
    this.map && this.map.getAllOverlays('marker').map((item: any) => {
      // 当前item在选中的点里面我们就设置icon为高亮
      const id = item.getExtData()
      let flag = false
      flag = val.some((item: any) => {
        return item.locationId === id
      })
      if (flag) {
        item.setIcon(new AMap.Icon({
          size: new AMap.Size(25, 35),
          image: this.baseUrl2,
          imageSize: new AMap.Size(25, 35)
        }))
      } else {
        item.setIcon(new AMap.Icon({
          size: new AMap.Size(25, 35),
          image: this.baseUrl,
          imageSize: new AMap.Size(25, 35)
        }))
      }
    })
  }

  onSearch () {
    // this.map && this.map.clearMap()
    if (!this.info.projectId) {
      this.$message.warning('请选择所属项目')
      return
    }
    if (this.info.checkType === '1') {
      if (!this.searchInfo.seedlingTypes.length) {
        this.$message.warning('请选择苗木类别')
        return
      }
      // console.log('this.searchInfo.seedlingTypes', this.searchInfo.seedlingTypes)
      const params = JSON.parse(JSON.stringify(this.searchInfo))
      if (params.seedlingTypes.length) {
        params.seedlingTypes = params.seedlingTypes.join(',')
      }
      this.getSeedlingseedList(params, false)
    } else {
      if (!this.searchInfo.projectAreaIds) {
        this.$message.warning('请选择分区类别')
        return
      }
      this.getSeedlingseedList(this.searchInfo, false)
    }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (!this.info.seedlingCheckRecordList.length) {
          this.$message.warning('至少选择一颗苗木')
          return
        }
        // console.log('提交数据', this.info)
        const params = JSON.parse(JSON.stringify(this.info))
        params.seedlingCheckRecordList = params.seedlingCheckRecordList.map((item: any) => {
          return {
            seedlingId: item.seedlingId,
            planNum: item.amount
          }
        })
        this.loadingSubmit = true
        this.$axios.post(this.$apis.seedlingInspection.insertYhSeedlingCheck, params).then(() => {
          this.$message.success('新增成功')
          this.$router.push({ name: 'seedlingInspection' })
        }).finally(() => {
          this.loadingSubmit = false
        })
      }
    })
  }
}
